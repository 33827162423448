@import "react-phone-number-input/style.css";
@import "intro.js/introjs.css";

html {
  font-size: 16px;
}
input {
  min-width: 0;
  max-width: 100%;
}
th,
td {
  text-align: left;
}

td {
  vertical-align: top;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  :root {
    font-size: 16px;
  }
  body {
    @apply text-base;
    @apply font-medium;
  }

  /* disable search input decoration */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  b,
  th,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-semibold;
  }
}

@layer components {
  .icon {
    @apply inline-block;
  }
  @media (hover: hover) {
    .has-hover-child .hover-child {
      @apply transition-opacity;
    }
    .has-hover-child .hover-child {
      @apply opacity-0;
    }
    .has-hover-child .hover-child.hide-on-hover {
      @apply opacity-100;
    }
    .has-hover-child:hover .hover-child {
      @apply opacity-100;
    }
    .has-hover-child:hover .hover-child.hide-on-hover {
      @apply opacity-0;
    }
  }
  .markdown p {
    margin-block-start: 1.25rem;
    margin-block-end: 1.25rem;
  }
  .markdown ol,
  .markdown ul {
    list-style: auto;
    padding-inline-start: 1.25rem;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
  }
  .markdown li {
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
  }
  .markdown li > * {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  /* purgecss start ignore */
  .current-page\:bg-blue-100.active {
    @apply bg-blue-100;
  }
  .current-page\:text-blue-900.active {
    @apply text-blue-900;
  }
  /* purgecss end ignore */
}

@layer base {
  select.icon-light {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  }
}

@layer utilities {
  .rotate-270 {
    transform: rotate(270deg);
  }
  .rotate-180 {
    transform: rotate(180deg);
  }
  .rotate-90 {
    transform: rotate(90deg);
  }
}
